import { ClipboardDocumentListIcon } from '@heroicons/react/24/outline'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import ResumePDF from '../assets/Ishaan_Tiwari_Resume.pdf'

export default function Hero() {
    return (
        <div className="relative isolate px-6 pt-14 lg:px-8" id='portfolio'>
            
            {/* Design */}
            <div
                className="absolute inset-x-0 -top-60 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-120"
                aria-hidden="true"
            >
                <div
                    className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                    style={{
                    clipPath:
                        'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                    }}
                />
            </div>

            <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
                {/* <div className="hidden sm:mb-8 sm:flex sm:justify-center">
                    <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                    Announcing my latest blog post on {} .{' '}
                    <a href="#" className="font-semibold text-indigo-600">
                        <span className="absolute inset-0" aria-hidden="true" />
                        Read more <span aria-hidden="true">&rarr;</span>
                    </a>
                    </div>
                </div> */}
                <div className="text-center">
                    
                    <p className='mt-6 text-2xl leading-8 text-gray-600 font-light'>
                        Hi, my name is
                    </p>
                    <h1 className="mt-4 text-4xl tracking-tight text-blue-500 sm:text-6xl font-serif italic">
                        Ishaan Tiwari.
                    </h1>
                    <p className="mt-6 text-2xl leading-8 text-gray-600 font-light">
                        I'm a grad student at CMU and an aspiring Product Manager
                        <br/>
                        Ex-Software Developer at Amazon and Quince.
                    </p>

                    <div className="mt-10 flex items-center justify-center">
                        <a href='https://www.linkedin.com/in/theishaantiwari' target="_blank">
                            <div className="flex items-center justify-center w-10 h-10 bg-gray-100 hover:bg-gray-200 rounded-md mr-4 border">
                                <FontAwesomeIcon icon={faLinkedinIn} className="h-6 w-6 text-blue-500" />
                            </div>
                        </a>
                        <a href={ResumePDF} target="_blank" rel="noopener noreferrer">
                            <div className="flex items-center justify-center w-10 h-10 bg-gray-100 hover:bg-gray-200 rounded-md mr-4 border">
                                <ClipboardDocumentListIcon className='h-6 w-6 text-blue-500'/>
                            </div>
                        </a>
                    </div>

                </div>
            </div>

            {/* Design */}
            <div
                className="absolute inset-x-0 top-[30%] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[30%]"
                aria-hidden="true"
            >
                <div
                    className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                    style={{
                    clipPath:
                        'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                    }}
                />
            </div>

        </div>
    )
}