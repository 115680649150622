import StowPlanner from "../assets/stow_planner.png"
import ProblemSolve from "../assets/problem_solve.png"
import Spotify from "../assets/spotify.png"
// import WishlistSharing from "../assets/wishlist_sharing.jpeg"
import Instagram from "../assets/instagram.png"



export default function Projects() {
    return (
        <section class="bg-white mb-40 mt-20">
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
            <div class="mx-auto max-w-2xl lg:mx-0">
            <h2 class="text-3xl tracking-tight text-blue-500 sm:text-4xl font-serif italic">My portfolio</h2>
            <p class="mt-2 text-lg leading-8 text-gray-600">A window into my gallery of accomplishments.</p>
            </div>
            <div class="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            

            <a href="https://medium.com/@theishaantiwari/ending-the-doom-scroll-86db3dc162e4" target="_blank">
                <article class="flex max-w-xl flex-col items-start justify-between">
                    <img
                        className="rounded-md shadow-md mb-5"
                        src={Instagram}
                    />
                    <div class="flex items-center gap-x-1 text-xs">
                    {/* <p class="relative rounded-full bg-[#FFA177] px-3 py-1.5 font-medium text-gray-800">Case Study</p> */}
                    <p class="relative rounded-full bg-gradient-to-r from-[#d62976] via-[#fa7e1e] to-[#feda75] px-3 py-1.5 font-medium text-white border">Instagram</p>
                    <p class="relative rounded-full bg-gray-200 px-3 py-1.5 font-medium text-gray-800 border">Product Design</p>
                    </div>
                    <div class="group relative">
                    <h3 class="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                        Instagram Case Study
                    </h3>
                    <p class="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">Doom-scrolling harms teen mental health. Gamification can turn passive scrolling into active, meaningful engagement.</p>
                    </div>
                </article>
            </a>

            <a href="https://medium.com/@theishaantiwari/getting-developing-countries-streaming-acfbbfe87127" target="_blank">
                <article class="flex max-w-xl flex-col items-start justify-between">
                    <img
                        className="rounded-md shadow-md mb-5"
                        src={Spotify}
                    />
                    <div class="flex items-center gap-x-1 text-xs">
                    {/* <p class="relative rounded-full bg-[#FFA177] px-3 py-1.5 font-medium text-gray-800">Case Study</p> */}
                    <p class="relative rounded-full bg-[#1ED760] px-3 py-1.5 font-medium text-black border">Spotify</p>
                    <p class="relative rounded-full bg-gray-200 px-3 py-1.5 font-medium text-gray-800 border">Product Strategy</p>
                    </div>
                    <div class="group relative">
                    <h3 class="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                        Spotify Case Study
                    </h3>
                    <p class="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">Spotify struggles with conversion in developing markets. How can it drive habit formation and conversions?</p>
                    </div>
                </article>
            </a>


            <a href="https://medium.com/@theishaantiwari/stow-planner-warehouse-at-a-glance-d94a9a826cf0" target="_blank">
                <article class="flex max-w-xl flex-col items-start justify-between">
                    <img
                        className="rounded-md shadow-md mb-5"
                        src={StowPlanner}
                    />
                    <div class="flex items-center gap-x-1 text-xs">
                    <p class="relative rounded-full bg-[#FF9900] px-3 py-1.5 font-medium text-white border">Amazon</p>
                    {/* <p class="relative rounded-full bg-[#27ae60] px-3 py-1.5 font-medium text-white">Back end</p> */}
                    </div>
                    <div class="group relative">
                    <h3 class="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                        Stow Planner
                    </h3>
                    <p class="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">Designed and implemented the back-end for Stow Planner, a tool offering real-time visibility into space in a warehouse.</p>
                    </div>
                </article>
            </a>

            {/*
            <a href="" target="_blank">
                <article class="flex max-w-xl flex-col items-start justify-between">
                    <img
                        className="rounded-md shadow-md mb-5"
                        src={WishlistSharing}
                    />
                    <div class="flex items-center gap-x-1 text-xs">
                    <p class="relative rounded-full bg-[#FFA177] px-3 py-1.5 font-medium text-gray-800">Quince</p>
                    </div>
                    <div class="group relative">
                    <h3 class="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                        Review System
                    </h3>
                    <p class="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">Product Owner for Product Review System, implemented with MySQL and ElasticSearch in Kotlin (Spring).</p>
                    </div>
                </article>
            </a>
            */}

            <a href="https://medium.com/@theishaantiwari/problem-solve-streamlining-logistics-04ab39605da6" target="_blank">
                <article class="flex max-w-xl flex-col items-start justify-between">
                    <img
                        className="rounded-md shadow-md mb-5"
                        src={ProblemSolve}
                    />
                    <div class="flex items-center gap-x-1 text-xs">
                    <p class="relative rounded-full bg-[#FF9900] px-3 py-1.5 font-medium text-white border">Amazon</p>
                    {/* <p class="relative rounded-full bg-[#3498db] px-3 py-1.5 font-medium text-white">Front end</p> */}
                    </div>
                    <div class="group relative">
                    <h3 class="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                        Problem Solve
                    </h3>
                    <p class="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">Implemented the front-end for Problem Solve, an inventory anomaly reporting tool, using React and Typescript.</p>
                    </div>
                </article>
            </a>

            </div>
        </div>
        </section>
    )
}
