import ResumePDF from '../assets/Ishaan_Tiwari_Resume.pdf'
import { Timeline } from 'flowbite-react';
import { BarsArrowDownIcon, BarsArrowUpIcon } from '@heroicons/react/24/outline'
import { useState } from 'react';

export default function CustomTimeline() {
    return (
      <section id='resume'>
        <div className="bg-white text-black py-8">
          <div className="container mx-auto flex flex-col items-start md:flex-row my-12 md:my-24">
            <div className="flex flex-col w-full sticky md:top-36 lg:w-1/3 mt-2 md:mt-12 px-8 mb-20">
              <h2 class="text-3xl tracking-tight text-blue-500 sm:text-4xl font-serif italic">My Résumé</h2>
              <p class="mt-2 text-lg leading-8 text-gray-600 mb-4">An overview of my professional journey so far.</p>
              <a
                href={ResumePDF} target="_blank" rel="noopener noreferrer"
                className="bg-transparent mr-auto hover:bg-blue-500 hover:text-white rounded shadow hover:shadow-lg py-2 px-4 border border-blue-500 hover:border-transparent"
              >
                Download PDF
              </a>
            </div>
            <div className="ml-0 md:ml-12 lg:w-2/3 sticky">
              <div className="container mx-auto w-full h-full">
                <div className="relative wrap overflow-hidden p-10 h-full">
                  <Component />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
       
  )
}

function Component() {
  return (
    <Timeline>
      <Cmu />
      <Quince />
      <Amazon />
      <Bny />
      <Ceeri />
      <Bits />
    </Timeline>
  );
}

function Bits() {
  const [bits, setBits] = useState(false)
  const toggleBits = () => setBits(!bits);
  return (
    <Timeline.Item>
      <Timeline.Point />
      <Timeline.Content>
        <Timeline.Time>August 2017 - August 2021</Timeline.Time>
        <Timeline.Title>Birla Institute of Technology & Science, Pilani</Timeline.Title>
        <Timeline.Body className='text-gray-600'>B.E. Electrical & Electronics Engineer</Timeline.Body>
        {bits ?
          <div className='flex gap-2 items-center' onClick={toggleBits}><BarsArrowUpIcon className='h-4 w-4 text-yellow-500'/></div> :
          <div className='flex gap-2 items-center' onClick={toggleBits}><BarsArrowDownIcon className='h-4 w-4 text-blue-500'/></div>
        }
        {
          bits &&
          <div className='text-sm pt-3 text-justify font-light'>
            <p className='mb-2'>
              CGPA: 8.65/10 (First Division)
              <br/>
              Electives: Machine Learning, Data Structures and Algorithms, Object Oriented Programming, Cryptography, Operating Systems, IoT
            </p>
            <ul style={ {listStyle: 'disc', paddingLeft: 25} }>
              <li>Two-time recipient of the Institute Merit Scholarship, awarded to the top 1% of each batch every semester</li>
              <li>Awarded a transfer within the same tier, in recognition of my exceptionally meritorious academic performance. Such transfers were granted to a total of 10 people out of 1,200.</li>
              <li>Worked under Dr. Syed Mohammad Zafaruddin - Developing Optical Wireless Communication systems that work in the visible spectra using Deep Learning, overcoming the channel defects of turbulence, fogginess, or combinations of both.</li>
              <li>Head, Department of Sponsorship and Marketing for BOSM - Led a three-tiered team of 30 people working towards raising sponsorship for the BITS Open Sports Meet (BOSM), BITS Pilani's annual international sports festival.</li>
              <li>Committee of Students for Sports Activities - Part of an eight-member elected body liaising between BITS Pilani and its students on all sporting activities, and responsible for taking all administrative decisions for BOSM.</li>
              {/* <li>Interned at the Central Electronics Engineering Reseach Institute. Worked under Dr. Suriya Prakash to evaluate the impact of modifying various components within Single Image Depth Estimation convolutional neural networks using Python and TensorFlow.</li> */}
              {/* <li>Interned at the Bank of New York Mellon. Automated run-time capture for the Data Design team’s pipelines in Pentaho, and optimized overall run-time for select pipelines by updating the SQL queries and creating indices over frequently read table.</li> */}
            </ul>
          </div>
        }
      </Timeline.Content>
    </Timeline.Item>
  )
}

function Amazon() {
  const [amazon, setAmazon] = useState(true)
  const toggleAmazon = () => setAmazon(!amazon);
  return(
    <Timeline.Item>
      <Timeline.Point />
      <Timeline.Content>
        <Timeline.Time>July 2021 - October 2023</Timeline.Time>
        <Timeline.Title>Amazon</Timeline.Title>
        <Timeline.Body className='text-gray-600'>Software Developer</Timeline.Body>
        {amazon ?
          <div className='flex gap-2 items-center' onClick={toggleAmazon}><BarsArrowUpIcon className='h-4 w-4 text-yellow-500'/></div> :
          <div className='flex gap-2 items-center' onClick={toggleAmazon}><BarsArrowDownIcon className='h-4 w-4 text-blue-500'/></div>
        }
        {
          amazon &&
          <div className='text-sm pt-3 text-justify font-light'>
            <p className='mb-2'>
              Worked with the Global Mile, Distribution Center Tech, and Amazon Fulfillment Tech teams to build logistics solutions that scaled to millions of transactions per second.
            </p>
            <ul style={ {listStyle: 'disc', paddingLeft: 25} }>
              <li>Collaborated with global product managers, designers, and developers to launch warehouse management tools, streamlining operations and processing millions of packages daily.</li>
              <li>Redesigned a legacy tool using stakeholder interview insights and clickstream analysis, eliminating weekly outages and reducing operational costs by 40%.</li>
              <li>Led a team of three developers to deliver a warehouse space optimization tool, improving storage visibility and increasing efficiency by 9%; reduced latency by 70% through performance tuning and optimizations.</li>
              <li>Built SQL-based accounting validation systems, enabling digital migration of 10M+ in inventory.</li>
              <li>Recognized twice for exemplifying Amazon's Leadership Principle of Ownership.</li>
            </ul>
          </div>
        }
      </Timeline.Content>
    </Timeline.Item>
  )
}

function Quince() {
  const [quince, setQuince] = useState(true)
  const toggleQuince = () => setQuince(!quince);
  return(
    <Timeline.Item>
      <Timeline.Point />
      <Timeline.Content>
        <Timeline.Time>October 2023 - August 2024</Timeline.Time>
        <Timeline.Title>Quince</Timeline.Title>
        <Timeline.Body className='text-gray-600'>Product Owner/ Software Development EngineerII – Review System</Timeline.Body>
        {quince ?
          <div className='flex gap-2 items-center' onClick={toggleQuince}><BarsArrowUpIcon className='h-4 w-4 text-yellow-500'/></div> :
          <div className='flex gap-2 items-center' onClick={toggleQuince}><BarsArrowDownIcon className='h-4 w-4 text-blue-500'/></div>
        }
        {
          quince &&
          <div className='text-sm pt-3 text-justify font-light'>
            <p className='mb-2'>
              Quince is a sustainable luxury clothing e-commerce startup valued at 1.8 billion USD
            </p>
            <ul style={ {listStyle: 'disc', paddingLeft: 25} }>
              <li>Defined and prioritized product requirements, leading end-to-end development of a Review System that increased weekly review rates by 25%, driving higher customer engagement.</li>
              <li>Collaborated with cross-functional teams to design and launch personalized post-purchase email features, increasing User-Generated Content (UGC) contributions by 60% through data-driven A/B testing.</li>
              <li>Implemented AI sentiment analysis and automated content moderation tools with Data Science teams, improving review quality and reducing approval time by 90%.</li>
              <li>Collaborated with logistics teams to add estimated delivery date tags on collection pages, increasing CTR to product detail pages by 6%.</li>
            </ul>
          </div>
        }
      </Timeline.Content>
    </Timeline.Item>
  )
}

function Ceeri() {
  const [ceeri, setCeeri] = useState(false)
  const toggleCeeri = () => setCeeri(!ceeri);
  return(
    <Timeline.Item>
      <Timeline.Point />
      <Timeline.Content>
        <Timeline.Time>May 2019 - July 2019</Timeline.Time>
        <Timeline.Title>CSIR - Central Electronics Engineering Research Institute (CEERI)</Timeline.Title>
        <Timeline.Body className='text-gray-600'>Machine Vision Intern</Timeline.Body>
        {ceeri ?
          <div className='flex gap-2 items-center' onClick={toggleCeeri}><BarsArrowUpIcon className='h-4 w-4 text-yellow-500'/></div> :
          <div className='flex gap-2 items-center' onClick={toggleCeeri}><BarsArrowDownIcon className='h-4 w-4 text-blue-500'/></div>
        }
        {
          ceeri &&
          <div className='text-sm pt-3 text-justify font-light'>
            <p className='mb-2'>
              CEERI is a premier institution established by the Government of India for the advancement of R&D in the field of Electronics.
            </p>
            <ul style={ {listStyle: 'disc', paddingLeft: 25} }>
            <li>Worked under Dr. Suriya Prakash to evaluate the impact of modifying various components within Single Image Depth Estimation convolutional neural networks using Python and TensorFlow.</li>
            </ul>
          </div>
        }
      </Timeline.Content>
    </Timeline.Item>
  )
}

function Bny() {
  const [bny, setBny] = useState(false)
  const toggleBny = () => setBny(!bny);
  return(
    <Timeline.Item>
      <Timeline.Point />
      <Timeline.Content>
        <Timeline.Time>August 2020 - December 2020</Timeline.Time>
        <Timeline.Title>Bank of New York (BNY) Mellon</Timeline.Title>
        <Timeline.Body className='text-gray-600'>Intern</Timeline.Body>
        {bny ?
          <div className='flex gap-2 items-center' onClick={toggleBny}><BarsArrowUpIcon className='h-4 w-4 text-yellow-500'/></div> :
          <div className='flex gap-2 items-center' onClick={toggleBny}><BarsArrowDownIcon className='h-4 w-4 text-blue-500'/></div>
        }
        {
          bny &&
          <div className='text-sm pt-3 text-justify font-light'>
            <p className='mb-2'>
              Worked as a part of the Data Design team at BNY Mellon, partnering closely with business teams to ensure data assets are designed and developed with an eye towards extensibility and future use.
            </p>
            <ul style={ {listStyle: 'disc', paddingLeft: 25} }>
              <li>Automated run-time capture for the Data Design team’s pipelines in Pentaho, and optimized overall run-time for select pipelines by updating the SQL queries and creating indices over frequently read tables.</li>
            </ul>
          </div>
        }
      </Timeline.Content>
    </Timeline.Item>
  )
}

function Cmu() {
  const [cmu, setCmu] = useState(false)
  const toggleCmu = () => setCmu(!cmu);
  return (
    <Timeline.Item>
      <Timeline.Point />
      <Timeline.Content>
        <Timeline.Time>August 2024 - December 2025</Timeline.Time>
        <Timeline.Title>Carnegie Mellon University</Timeline.Title>
        <Timeline.Body className='text-gray-600'>M.S. Software Management (with a focus in Product Management)</Timeline.Body>
        {cmu ?
          <div className='flex gap-2 items-center' onClick={toggleCmu}><BarsArrowUpIcon className='h-4 w-4 text-yellow-500'/></div> :
          <div className='flex gap-2 items-center' onClick={toggleCmu}><BarsArrowDownIcon className='h-4 w-4 text-blue-500'/></div>
        }
        {
          cmu &&
          <div className='text-sm pt-3 text-justify font-light'>
            <p className='mb-2'>
              CGPA: 4.0
              <br/>
              Coursework: Product Management, Innovation and Entrepreneurship, Problem Discovery, Human-Computer Interaction, Business Strategy, Metrics, AI Applications, AI in Business Modelling, Marketing
            </p>
              <i>Teaching Assistant & Project Manager – Software Design and Architecture</i>
              <ul style={ {listStyle: 'disc', paddingLeft: 25} }>
                <li>Leading Agile development for 40+ graduate students, mentoring in user needs analysis, feature prioritization, and on-time delivery while strengthening their architecture skills.</li>
              </ul>
              <i>Agentic AI-based Travel Application</i>
              <ul style={ {listStyle: 'disc', paddingLeft: 25} }>
                <li>Designed high-fidelity prototypes using Figma for a travel service using 10+ user interviews and UX iterations.</li> <li>Conducted competitive analysis and crafted a go-to-market strategy for a trip lifecycle app.</li>
              </ul>
              <i>AI-based Ticketing Software </i>
                <ul style={ {listStyle: 'disc', paddingLeft: 25} }>
                  <li>Analyzed 500+ Product Manager survey responses to identify stakeholder alignment issues. Designed an AIpowered ticketing solution, saving PMs ~2 hours daily (25%), validated via user interviews.</li>
              </ul>
              <li><i>Business Analytics of Veeva (NYSE: VEEV) </i>
              <ul style={ {listStyle: 'disc', paddingLeft: 25} }><li>Analyzed Veeva (NYSE:VEEV)’s business model and finances over the last 5 years, conducting SWOT analysis and trend forecasting to inform strategic product recommendations.</li></ul></li>
          </div>
        }
      </Timeline.Content>
    </Timeline.Item>
  )
}
